
export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'slogan'].includes(value),
    },
  },
}
